<template>
  <div v-if="this.value !== undefined"  class="flex justify-content-start align-items-center py-1">  
    <div class="hidden sm:flex justify-content-center person-item m-0">
        <!-- <small class="">{{ titleStr }}</small> -->
    </div>
    <span :class="`person-tag ml-auto fg-lightGray p-1 line-height-3 text-sm ${showPointer1} ${this.col1}`" v-tooltip="`<span class='fg-lightYellow'>${value.description}</span><hr class='mt-1 mb-1' />${timestamp_1}`" @click="toggle1" @dblclick="toggle">
      <i :class="'fi ' + icon_1 + ' icon-inline text-base mr-1 fg-amber'"></i>{{ value1PlusMinus }}{{ value_1.value }}<span class="text-xs unit">{{ unit_1 }}</span>
      <Menu :id="'valueMenu1_' + 1" ref="menu1" :model="valueMenu1" :popup="true" :baseZIndex="100" />
    </span>
  </div>
  <dialog-chart-single :icon="icon_1" :node="value" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
  <dialog-multi-change :showDialog="showMultiChange1" :node="[value]" @entryAbort="showMultiChange1 = false"></dialog-multi-change>
</template>

<script>
import { defineComponent } from "vue";
import { formatDateGui } from "@/helpers";
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import dialogMultiChange from '@/components/dialog/dialogMultiChange.vue';

export default defineComponent({
  name: "widgetSingleValue",
  setup() {},
  components: {
   dialogChartSingle,
   dialogMultiChange
  },
  props: {
    title: {
      type: String,
      required: false
    },
    value: {
      type: null,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false
    },
    valueTimemode: {
      type: null,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      showChart1: false,
      showChart2: false,
      showMultiChange1: false,
      showMultiChange2: false,
    }
  },
  watch: {

  },
  computed: {
    titleStr: function () {
      if (this.title) return this.title;
      return `${this.value.label}`;
    },
    value1_str: function () {
      if (this.value.label) return this.value.label;
      return "";
    },
    col1: function () {
      if (this.color !== undefined) {
        return this.color;
      } else {
        return 'bg-darkOlive';
      }
    },
    value_1: function () {
      return this.parseValue(this.value);
    },
    unit_1: function () {
      return this.value.unitStr;
    },
    timestamp_1: function () {
      if (this.value.timestamp) {
        return formatDateGui(this.value.timestamp);
      }
      return '';
    },
    icon_1: function () {
      if (this.value.unit === "#HMS") {
        if (this.valueTimemode) {
          if (this.valueTimemode.value === 1) {
            return "fi-rr-sunrise-alt";
          } else if (this.valueTimemode.value === 2) {
            return "fi-rr-sunset";
          }
        }
        return "fi-rr-clock";
      }
      if (this.icon) {
        return this.icon;
      }
      return this.value.icon;
    },
    value1PlusMinus: function () {
      if (this.valueTimemode !== undefined && this.valueTimemode.value > 0  && this.value.unit === "#HMS") {
        if (this.value.valueRaw < 0) return "-";
        else return "+";
      }
      return "";
    },
    isRecord1() {
      if (typeof(this.value) === "object") {
        if (this.value.record === "true") return true;
      }
      return false;
    },
    isWriteable1() {
      // if (typeof(this.value1) === "object") {
      //   if (this.value1.writeable === "true") return true;
      // }
      return false;
    },
    showPointer1() {
      if (this.isRecord1) return "cursor-pointer";
      else return "";
    },
    value1Contains() {
      if (this.isRecord1 || this.isWriteable1) return true;
      else return false;
    },
    valueMenu1() {
      let arr = [];
      if (this.isRecord1) {
        arr.push(
          {
            label: 'Statistik',
            icon: 'mif-chart-dots',
            command: () => {
              this.showChart('value1');
            }
          }
        )
      }
      if (this.isWriteable1) {
        arr.push(
          {
            label: 'Wert ändern',
            icon: 'mif-cog',
            command: () => {
              this.showMultiChange('value1');
            }
          }
        )
      }
      return arr;
    },
  },
  mounted() {
  },
  methods: {
    toggle1(event) {
      if (this.value1Contains) this.$refs.menu1.toggle(event);
    },
    parseValue(val) {
      const value = { ...val };
      if (value.unit === '#BOOL') {
        if (value.value === true) value.value = '🟢Ein';
        else value.value = '🔴Aus';
      }
      return value;
    },
    showChart(value) {
      if (value === 'value1' && this.isRecord1) this.showChart1 = true;
    },
    showMultiChange(value) {
      if (value === 'value1' && this.isWriteable1) this.showMultiChange1 = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.person-tag {
  // background: linear-gradient(45deg, rgba(15, 23, 42, 0.8), rgba(25, 39, 72, 0.8)) !important;
  // background: linear-gradient(45deg, rgba(39, 39, 39, 0.7), rgba(39, 39, 39, 0.5)) !important;
  background: linear-gradient(45deg, rgba(55, 60, 56, 0.7), rgba(55, 60, 56, 0.5)) !important;
  color: #fcfaf2 !important;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, .2) inset,
    0 0 0 1px rgba(0, 0, 0, .2),
    0 -1px 0 0 rgba(0, 0, 0, .2) inset,
    0 1px 2px 1px rgba(0, 0, 0, .1);
  border: none;
  outline: none;
  border-radius: 4px !important;
}
.person-tag:hover {
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, .3) inset,
    0 0 0 1px rgba(0, 0, 0, .3),
    0 -1px 0 0 rgba(0, 0, 0, .3) inset,
    0 1px 2px 1px rgba(0, 0, 0, .2);
}
.person-tag:active {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .7) inset,
    0 1px 0 0 rgba(255, 255, 255, .05),
    0 1px 2px 1px rgba(0, 0, 0, .6) inset;
}
.person-tag .unit {
  color: #fedfe1 !important;
}
</style>